import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  groupURLs: {},
  productURLs: {},
  isFetched: false,
};

const urlSlice = createSlice({
  name: "url",
  initialState,
  reducers: {
    setIsFetched: (state) => {
      state.isFetched = true;
    },
    storeProductUrls: (state, action) => {
      state.productURLs = action.payload;
    },
    storeGroupUrls: (state, action) => {
      state.groupURLs = action.payload;
    },
  },
});

export const { setIsFetched, storeProductUrls, storeGroupUrls } =
  urlSlice.actions;

export default urlSlice.reducer;
